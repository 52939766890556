.message {
    padding: 20px;
    margin: 20px;
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    color: #fff;
  }
  
  .alreadyResponded {
    background-color: #f44336; /* Red for already responded message */
  }
  
  .cannotView {
    background-color: #ff9800; /* Orange for can't view message */
  }
  
  .loading {
    background-color: #2196f3; /* Blue for loading message */
  }
  
  .survey {
    padding: 20px;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  